<template>
  <div class='ps__container'>
    <div class='ps__chapters'>
      <ChapterLabel 
        v-for='chapter in chapters'
        :key='chapter'
        :chapter='chapter'
        @changeChapter='(chapter,state) => onChangeChapter(chapter,state)'/>
    </div>
  </div>
</template>

<script>
import ChapterLabel from './ChapterLabel.vue'

  export default {
    components: {
      ChapterLabel
    },
    data() {
      return {
        selectedChapters: []
      }
    },
    emits: ['changeChapters'],
    methods: {
      onChangeChapter(chapter,state) {
        const index = this.selectedChapters.indexOf(chapter);
        if (state && index===-1) {
          this.selectedChapters.push(chapter)
        } else if (!state && index>-1) {
          this.selectedChapters.splice(index,1)
        }
        this.$emit('changeChapters', this.selectedChapters)
      }
    },
    name: 'PracticeSelector',
    props: {
      chapters: Array
    }
  }
</script>

<style scoped>
  .ps__container {
    overflow-y: auto;
    margin-inline: 1rem;
  }
  .ps__chapters {
    padding-block: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
</style>