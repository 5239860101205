<template>
  <div class='actions'>
    <button 
      class='start'
      :disabled='isDisabled()'
      @click="$emit('start')"
      >Aρχίζω</button>
  </div>
</template>

<script>
  export default {
    name: 'StartButton',
    emits: ['start'],
    props: {
      disabled: Boolean,
    },
    methods: {
      isDisabled() {
        return !this.disabled
      }
    }
  }
</script>

<style scoped>
  .actions {
    border-top: 2px solid var(--col-brand);
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: var(--col-base1);
  }
  button.start {
    padding: .5rem 1rem;
    font-size: 1.5rem;
    background-color: var(--col-brand);
    color: var(--col-base1);
    border: none;
    border-radius: .5rem;
    min-width: 10rem;
  }
  button.start[disabled] {
    background-color: var(--col-sup2);
    color: var(--col-base1);
  }
</style>