<template>
  <label 
    class='ps__chapter'>
    <input 
      type='checkbox' 
      v-on="listeners" 
      v-model='isChecked' />
    <div 
      :class="['marker', (isChecked) ? 'checked' : '']">
    </div>
    <div class='chapter__name'>{{ chapter }}</div>
  </label>
</template>

<script>
  export default {
    data() {
      return {
        isChecked: false
      }
    },
    emits: ['changeChapter'],
    props: {
      chapter: String
    },
    computed: {
      listeners () {
        return {
          input: (event) =>  {
            let _checked = false
            if (event.target.checked) {
              _checked = true
            }
            this.isChecked = _checked
            this.$emit('changeChapter', this.chapter, _checked)
          }
        }
      },
    }
  }
</script>

<style scoped>
  .ps__chapter {
    display: grid;
    background-color: var(--col-sup2);
    border-radius: 0.5rem;
    grid-template-columns: 1fr 4fr;
    border: 1px solid var(--col-sup2);
    overflow: hidden;
  }

  .chapter__name {
    display: inline-block;
    color: var(--col-brand);
    border-radius: .25rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    padding: 0.25rem;
  }
  input[type="checkbox"] {
    position: absolute;
    left: -99999;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .marker {
    background-color: var(--col-base1);
    border: 6px solid white;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .marker.checked {
    background-color: var(--col-sig1)
  }
</style>