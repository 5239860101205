<template>
  <TopBar />
  <PracticeSelector 
    v-if='home'
    :chapters='getChapters()' 
    @changeChapters='(chapters) => onChangeChapters(chapters)'/>
  <SettingsBar 
    v-if='home'
    :lang='getLang()'
    @switchLanguage='(lang) => onSwitchLanguage(lang)'/>
  <StartButton 
    v-if='home'
    :disabled='isReady()'
    @start='() => onStartClicked()'/>
  <PracticeChapters 
    v-if='practice'
    :practiceWords='getPracticeWords()'
    :repeatWords='getRepeatWords(getLang())'
    :lang='getLang()'
    @practiceDone='(repeat) => onPracticeDone(repeat)'
    @procesQuestion='(id, rating) => onProcesQuestion(id, rating)'
  />
</template>

<script>
import json from './data/all.json'
import PracticeChapters from './components/PracticeChapters.vue'
import PracticeSelector from './components/PracticeSelector.vue'
import StartButton from './components/StartButton.vue'
import TopBar from './components/TopBar.vue'
import SettingsBar from './components/SettingsBar.vue'

export default {
  name: 'HomePage',
  components: {
    PracticeChapters,
    PracticeSelector,
    StartButton,
    SettingsBar,
    TopBar
  },
  data() {
    return {
      allwords: json,
      options: '', 
      home: true,
      lexeisKey: 0,
      practice: false,
      selectedChapters: [],
      version: '2.1.0'
    }
  }, 
  methods: {
    createCookie(name, value, days) {
      var expires;
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toGMTString();
      }
      else {
          expires = "";
      }
      // document.cookie = name + "=" + escape(value) + expires + "; path=/";
      document.cookie = name + "=" + value + expires + "; path=/";
    },
    getChapters() {
      let _chapters = []

      // add the repeat chapter(s)
      if (this.options[this.getLang()] && this.options[this.getLang()].length > 0) {
        _chapters.push(this.getLang().toUpperCase())
      }
      this.allwords.forEach(function(item) {
        let _chapter = item.les;
        if (!_chapters.includes(_chapter) && item.les !='Extra') {
          _chapters.push(_chapter)
        }
      })

      _chapters.push('Extra')
      
      return _chapters
    },
    getCookie(c_name) {
      if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            // return unescape(document.cookie.substring(c_start, c_end));
            return document.cookie.substring(c_start, c_end);
        }
      }
      return "";
    },
    getLang() {
      let _lang = 'nlel'
      if (this.options['lang']) {
        _lang=this.options['lang']
      }
      return _lang
    },
    getPracticeWords() {
      let _words = [],
          _currentIndex = 0, 
          _randomIndex = 0,
          _allWords = []
      _allWords = this.getRepeatWords(this.getLang()).concat(this.allwords)

      this.selectedChapters.forEach((chapter) => {
        _allWords.forEach((word) => {
          if (word.les === chapter) {

            if (!_words.includes(word)) {
              _words.push(word)
            }
          }
        })
      })

      _currentIndex = _words.length
      while (_currentIndex != 0) {
        _randomIndex = Math.floor(Math.random() * _currentIndex)
        _currentIndex--
        [_words[_currentIndex], _words[_randomIndex]] = [_words[_randomIndex], _words[_currentIndex]]
      }
      return _words
    },
    getRepeatWords(lang) {
      let _repeat = []
      if (this.options[lang].length > 0) {
        let _allwords = this.allwords
        this.options[lang].forEach(function(wid) {
          var originalWord = _allwords.find(x => x.id === wid),
              copyWord = JSON.parse(JSON.stringify(originalWord));

          copyWord.les = lang.toUpperCase()
          _repeat.push(copyWord)
        })
      }
      return _repeat
    },
    initOptions() {
      let json_str = this.getCookie('l2_options'),
          arr = []

      if (json_str.length != 0) {
        arr = JSON.parse(json_str)
      }

      // version updated?
      if (arr['version'] === undefined || arr['version'] != this.version) {
        console.log('REMOVE cookies...')
      }
      if (arr['lang'] === undefined) {
        arr['lang'] = 'nlel'
      }
      if (arr['nlel'] === undefined) {
        arr['nlel'] = []
      }
      if (arr['elnl'] === undefined) {
        arr['elnl'] = []
      }
      // set to custom
      this.options = arr
      this.onSwitchLanguage(this.options['lang'])
    },
    isReady() {
      let _ready = false
      if (this.selectedChapters.length > 0) {
        _ready = true
      }
      return _ready
    },
    onChangeChapters(chapters) {
      this.selectedChapters = chapters
    },
    onPracticeDone(repeat) {
      this.selectedChapters = []
      this.options[this.getLang()] = repeat
      this.home = true
      this.practice = false
      this.updateOptions()
    },
    onStartClicked() {
      this.home = false
      this.practice = true
    },
    onSwitchLanguage(lang) {
      document.body.classList.remove('nlel')
      document.body.classList.remove('elnl')
      document.body.classList.add(lang)
      this.options.lang = lang
      this.updateOptions()
    },
    updateOptions() {
      let arr = {}
      arr['version'] = this.version
      arr['lang'] = this.options.lang
      arr['nlel'] = this.options.nlel
      arr['elnl'] = this.options.elnl
      this.createCookie('l2_options', JSON.stringify(arr), 30)
    },
  },
  mounted() {
    this.initOptions()
  }
}
</script>


<style>
:root {
  --col-brand: #1269C7;
  --col-base1: #FFFFFF;
  --col-base2: #000000;
  --col-sup1: #2A2A2A;
  --col-sup2: #CCCCCC;
  --col-sig1: #229955;
}
  html {
    font-size: 18px;
    line-height: 1.5;
  }
  body {
    margin: 0;
    padding: 0;
    background-color: var(--col-base1);
    color: var(--col-sup1);
  }
  a {
    color: var(--col-brand);
    text-decoration: none;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 575px;
    margin-inline: auto;
    max-height: 94vh;
    display: flex;
    flex-direction: column;
  }
  
</style>