<template>
  <div class='settings'>
    <div class='lang'>
      <div class='from'>NLΕΛ</div>
      <label class="switch">
        <input 
          type="checkbox"
          :checked='isChecked()'
          v-on="listeners">
        <span class="slider"></span>
      </label>
      <div class='to'>ΕΛNL</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SettingsBar',
    emits: ['switchLanguage'],
    props: {
      lang: String
    },
    methods: {
      isChecked() {
        return this.lang==='elnl'
      }
    },
    computed: {
      listeners () {
        return {
          input: event => this.$emit('switchLanguage', event.target.checked ? 'elnl' : 'nlel')
        }
      },
    }
  }
</script>

<style scoped>
  .settings {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    padding: .5rem;
    background-color: var(--col-brand);
    color: var(--col-base1);
  }
  .lang {
    display: flex;
  }
  .switch {
    --s-s: 16px;
    --s-sc: calc(var(--s-s) / 4);
    --s-w: calc((var(--s-s) + var(--s-sc)) * 2);
    --s-h: calc(var(--s-s) + (2 * var(--s-sc)));
    position: relative;
    width: var(--s-w);
    height: var(--s-h);
    margin-inline: 0.25rem;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196F3;
    border-radius: var(--s-h);
  }

  .slider:before {
    position: absolute;
    content: '';
    height: var(--s-s);
    width: var(--s-s);
    left: var(--s-sc);
    bottom: var(--s-sc);
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 50%;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(var(--s-s));
    -ms-transform: translateX(var(--s-s));
    transform: translateX(var(--s-s));
  }
</style>