<template>
  <div class='fc__container'>
    <div 
      class='flashcard'
      v-for='word in practiceWords'
      :key='word.id'
      :id='word.id'>
        <div class="info">
          <div class='chapter'>Les: {{ word.les }}</div>
          <div class='id'>Id: {{ word.id }}</div>
        </div>
        <div class="task">
          <div 
            class='question nlel'>Hoe zeg je dit in het Grieks?</div>
            <div 
            class='question elnl'>Πώς το λες αυτό στα Ολλανδικά;</div>
        </div>
        <div 
          class='word elnl'>{{ word.nl }}</div>
        <div 
          class='word nlel'>{{ word.el }}</div>
        <div class='actions'>
          <button 
            class='show'
            v-if='ask'
            v-on:click.stop='showAnswer(word.id)'>Δείξτε την απάντηση</button>
          <button 
            v-if='rate'
            class='rate wrong'
            v-on:click.stop='rateQuestion(word, false)'><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M12.1 6h22.2v25.6L20.4 46l-1.95-1.55q-.3-.25-.45-.7-.15-.45-.15-1.1v-.5L20.1 31.6H5.15q-1.2 0-2.1-.9-.9-.9-.9-2.1v-4.1q0-.35-.075-.725t.075-.725l6.3-14.5q.45-1.05 1.475-1.8Q10.95 6 12.1 6Zm19.2 3H11.45l-6.3 14.95v4.65H23.8l-2.65 12.45 10.15-10.7Zm0 21.35V9Zm3 1.25v-3h6.65V9H34.3V6h9.65v25.6Z"/></svg>Λάθος</button>
          <button 
            v-if='rate'
            class='rate right'
            v-on:click.stop='rateQuestion(word, true)'><svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M35.8 42H13.6V16.4L27.5 2l1.95 1.55q.3.25.45.7.15.45.15 1.1v.5L27.8 16.4h14.95q1.2 0 2.1.9.9.9.9 2.1v4.1q0 .35.075.725t-.075.725l-6.3 14.5q-.45 1.05-1.475 1.8Q36.95 42 35.8 42Zm-19.2-3h19.85l6.3-14.95V19.4H24.1l2.65-12.45-10.15 10.7Zm0-21.35V39Zm-3-1.25v3H6.95V39h6.65v3H3.95V16.4Z"/></svg>Σωστό</button>
        </div>
        <div class='more'
             v-on:click.stop='showModal()'></div>
      </div>
    </div>
  <StopButton 
    @stop='() => onStopClicked()'/>
</template>

<script>
  import StopButton from './StopButton.vue'

  export default {
    components: {
      StopButton
    },
    data() {
      return {
        ask: true,
        rate: false,
        repeat: []
      }
    },
    name: 'PracticeChapters',
    emits: ['practiceDone', 'procesQuestion'],
    props: {
      practiceWords: Array,
      repeatWords: Array,
      lang: String,
    },
    methods: {
      onStopClicked() {
        this.$emit('practiceDone', this.repeat)
      },
      showAnswer(id) {
        let _card = document.getElementById(id);

        _card.classList.add('answered')
        this.ask = false
        this.rate = true
      },
      showModal() {
        console.log('HIHIHIHI')
      },
      rateQuestion(word, rating) {
        let _card = document.getElementById(word.id),
            _cardN = _card.nextElementSibling;
        
        if (rating) {
          let x = this.repeat.indexOf(word.id)
          if (x > -1) {
            this.repeat.splice(x,1)
          }
        } else {
          if (word.les !== this.lang.toUpperCase()) {
            this.repeat.push(word.id)
          }
        }

        _card.classList.remove('is-shown')

        if (_cardN===null) {
          this.$emit('practiceDone', this.repeat)
          return
        }
        _cardN.classList.add('is-shown')
        this.ask = true
        this.rate = false
      }
    },
    mounted() {
      document.getElementsByClassName('flashcard')[0].classList.add('is-shown')
      this.repeatWords.forEach((word) => {
        if (word.les) {
          this.repeat.push(word.id)
        }
      })
    }
  }
</script>

<style scoped>
  .fc__container {
    height: 100vh;
  }
  .flashcard {
    position: relative;
    display: none;
    flex-direction: column;
    padding: 2rem 1rem 1rem;
    border-radius: 1rem;
    background-color: var(--col-sup2);
    margin: 1rem;
    min-height: 40vh;
  }
  .flashcard.is-shown {
    display: flex;
  }
  .info {
    display: flex;
    gap: 1rem;
    font-size: 0.75rem;
    line-height: 1;
    margin-bottom: 1rem;
  }
  .task {
    display: flex;
  }
  .question {
    display: none;
  }
  .nlel .question.nlel,
  .elnl .question.elnl {
    display: block;
  }
  .word {
    order: 0;
    font-size: 2rem;
  }
  .nlel .word.nlel,
  .elnl .word.elnl {
    order: 1;
    font-weight: bolder;
    color: transparent;
  }
  .flashcard .actions {
    order: 1;
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  button {
    position: relative;
    display: inline-block;
    padding: .5rem 2rem;
    background-color: var(--col-brand);
    color: var(--col-base1);
    border: none;
    border-radius: .5rem;
    margin-block: auto 0;
    width: fit-content;
    font-size: 1.25rem;
  }
  
  .nlel .answered .word.nlel,
  .elnl .answered .word.elnl {
    color: var(--col-brand);
  }
  .rate {
    padding-left: 3rem;
    padding-right: 1rem;
    max-width: 40vw;
  }
  .rate svg {
    position: absolute;
    margin-left: -2.5rem;
    margin-top: -0.5rem;
    fill: var(--col-base1);
    transform: scale(.6);
  }
  .wrong {
    background-color: red
  }
  .right {
    background-color: green;
  }
  .more {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .more::after {
    content: '?';
    display: block;
    text-align: center;
    color: var(--col-base1);
    line-height: 2;
    width: 2rem;
    height: 2rem;
    background-color: var(--col-brand);
    border-radius: 100%;
  }
</style>