<template>
  <div class='topbar'>
    <div class='title'>λέξεις</div>
  </div>
</template>

<script>
  export default {
    name: 'TopBar'
  }
</script>

<style scoped>
  .topbar {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding: .5rem;
    background-color: var(--col-brand);
    color: var(--col-base1);
  }
</style>