<template>
  <div class='actions'>
    <button 
      class='stop'
      @click="$emit('stop')"
      >Σταματώ</button>
  </div>
</template>

<script>
  export default {
    name: 'StopButton',
    emits: ['stop']
  }
</script>

<style scoped>
  .actions {
    border-top: 2px solid var(--col-brand);
    display: flex;
    justify-content: center;
    padding-inline: 1rem;
    padding-block: 2rem;
    background-color: var(--col-base1);
  }
  button.stop {
    padding: .5rem 1rem;
    font-size: 1.5rem;
    background-color: var(--col-brand);
    color: var(--col-base1);
    border: none;
    border-radius: .5rem;
    min-width: 10rem;
  }
</style>